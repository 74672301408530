import React from 'react';

import './SubmitButton.css'

interface Props {
    onPress: () => void;
    buttonLoading: boolean;
}

export const SubmitButton = (props: Props) => {
    return(
        <button disabled={props.buttonLoading} onClick={props.onPress} onSubmit={()=>false}>
            {props.buttonLoading ? 'AȘTEAPTĂ...' : 'TRIMITE FORMULAR'}
        </button>
    )
}