import React, { useState } from 'react';
import { ApplicationForm } from "../../components";
import { getActiveForm, postForm, getForm } from "../../api";
import { FormData as FormDataClass } from "../../types/classes";
import { formatData, validateForm } from "../../helpers/utils";
import { useNavigate, useParams } from 'react-router-dom';

import './Form.css';

const loadingIcon = require('src/assets/images/loading.gif');

export const Form = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
    const [formData, setFormData] = React.useState<FormDataClass>(null);
    const { formName } = useParams()

    React.useEffect(() => {
        setLoading(true);
        console.log(formName);
        getData(formName);
    }, [])

    const getData = async (formName?: string) => {
        try {
            console.log(formName);
            const response = !formName ? await getActiveForm() : await getForm(formName);
            const fromStorage = JSON.parse(localStorage.getItem('Jsu2k22Form'));
            if (!fromStorage) {
                localStorage.setItem('Jsu2k22Form', JSON.stringify({ participantInfo: [], questions: [] }))
            } else {
                fromStorage.participantInfo?.forEach(info => {
                    const index = response.participantInfo?.findIndex(item => item.fieldId === info.fieldId);

                    if (index) {
                        response.participantInfo[index].value = info.value;
                    }
                });

                fromStorage.questions?.forEach(question => {
                    const index = response.questions?.findIndex(item => item.questionId === question.questionId);

                    if (index)
                        response.questions[index].value = question.value;
                });
            }
            setFormData(response);
        } catch (e) {
            alert(e.message);
        } finally {
            setLoading(false);
        }
    }

    const onBlurSaveParticipantInfo = (fieldId: string, value: string) => {
        if (!value) return;
        const fromStorage = JSON.parse(localStorage.getItem('Jsu2k22Form'));

        const index = fromStorage.participantInfo?.findIndex(item => item.fieldId === fieldId);
        if (index === -1) {
            fromStorage.participantInfo.push({ fieldId, value });
        } else {
            fromStorage.participantInfo[index].value = value;
        }
        localStorage.setItem('Jsu2k22Form', JSON.stringify(fromStorage));
    }

    const onBlurSaveQuestion = (questionId: string, value: string) => {
        if (!value) return;
        const fromStorage = JSON.parse(localStorage.getItem('Jsu2k22Form'));
        const index = fromStorage.questions?.findIndex(item => item.questionId === questionId);
        if (index === -1) {
            fromStorage.questions.push({ questionId, value });
        } else {
            fromStorage.questions[index].value = value;
        }
        localStorage.setItem('Jsu2k22Form', JSON.stringify(fromStorage));
    }

    const onSubmitForm = async (participantInfo, questions, files) => {
        setButtonLoading(true);
        try {
            const valid = validateForm(formData, participantInfo, questions, files);
            if (!valid.status)
                return alert(valid.message)
            const data = formatData(formData.id, participantInfo, questions, files);
            if (!data) return;

            const response = await postForm(data);
            if (response.status === 201) {
                localStorage.removeItem('Jsu2k22Form');
                navigate('/success');
            }
        } finally {
            setButtonLoading(false);
        }
    }

    if (loading)
        return (
            <div className={"loadingContainer"}>
                <img src={loadingIcon} alt={"loading"} />
                <h1>SE ÎNCARCĂ</h1>
            </div>
        );

    return (
        <div className={"mainContainer"}>
            {/*<button onClick={()=> localStorage.clear()}>Reset Local Storage</button>*/}
            <ApplicationForm
                inputFields={formData}
                onSubmitForm={onSubmitForm}
                onBlurSaveParticipantInfo={onBlurSaveParticipantInfo}
                onBlurSaveQuestion={onBlurSaveQuestion}
                buttonLoading={buttonLoading}
                checkIfFormIsOpen={formName ? false : true}
            />
        </div>
    );
};