import {FORM_POST, ACTIVE_FORM_URL, FORMS_URL} from "../types/constants";
import {FormData} from "@src/types/classes";

const axios = require('axios');

export const getActiveForm = async (): Promise<FormData> => {
    try {
        const response = await axios.get(ACTIVE_FORM_URL)
            .then(function (response) {
                // handle success
                return response.data;
            })
            .catch(function (error) {
                // handle error
                alert(error);
            })
        return response;
    } catch (error) {
        alert(error);
    }
}

export const getForm = async (formId: string): Promise<FormData> => {
    try {
        const response = await axios.get(`${FORMS_URL}/${formId}`)
            .then(function (response) {
                // handle success
                return response.data;
            })
            .catch(function (error) {
                // handle error
                alert(error);
            })
        return response;
    } catch (error) {
        alert(error);
    }
}

export const postForm = async (data: any) => {
    try {
        const response = await axios.post(
            FORM_POST,
            data,
            {
                headers: {
                    'content-type': 'multipart/form-data'
                },
            }
        )
        return response;
    } catch (error) {alert(error)}
}