import {InputType} from "../types/enum";

export const validateCNP = (value: string) => {
    if(value?.length !== 13) return false;

    return /^\d+$/.test(value)
};

export const validateInput = (value: string) => {
    return value?.length > 0;
};

export const formatData = (registerFormId, participantInfo, questions, files) => {
    const answers = {
        registerFormId: registerFormId,
        participantInfo: {},
        answers: {},
    };

    participantInfo.forEach(info => answers.participantInfo[info.fieldId] = info.value)
    questions.forEach(question => answers.answers[question.questionId] = question.value)

    const formData = new FormData();
    formData.append('answers', JSON.stringify(answers));

    files.forEach(file => {
        formData.append(file.fileId, file.value);
    });

    return formData;
};

export const validateForm = (data,  participantInfo, questions, files) => {
    let status = true;
    let message = '';

    for (const info of data.participantInfo) {
        if(info.required) {
            let valid: boolean = true;

            if (info.fieldType === InputType.UPLOAD) {
                const index = files.findIndex(item => item.fileId === info.fieldId)

                if (index === -1)
                    return {status: false, message: `Nu ai atasat nimic in campul:   ${info.fieldLabel}!!`};
                if (files[index].value.size > 4700000)
                    return {status: false, message: `Ai depasit maximul de 4.5mb in campul:   ${info.fieldLabel}!!`};
            }

            const index = participantInfo.findIndex(item => item.fieldId === info.fieldId)

            if ((index === -1 && info.fieldType !== InputType.UPLOAD) || (index && info.fieldType !== InputType.UPLOAD && !participantInfo[index]?.value)) {
                valid = false;
            } else if (info.fieldType === InputType.CNP && !validateCNP(participantInfo[index]?.value)) {
                valid = false;
            }

            if (!valid)
                return {status: false, message: `Ai gresit campul:   ${info.fieldLabel}!!`};
        }
    }

    for (const info of data.questions) {
        if (info.required) {
            let valid = true;

            if (info.questionType === InputType.UPLOAD) {
                const index = files.findIndex(item => item.fileId === info.fieldId)

                if (index === -1)
                    return {status: false, message: `Nu ai atasat nimic in campul:   ${info.questionText}!!`};
                if (files[index].value.size > 4500)
                    return {status: false, message: `Ai depasit maximul de 4.5mb in campul:   ${info.questionText}!!`};
            }

            const index = questions.findIndex(item => item.questionId === info.questionId)

            if ((index === -1 && info.questionType !== InputType.UPLOAD) || (index && info.questionType !== InputType.UPLOAD && !questions[index]?.value))
                valid = false;
            else if (info.questionType === InputType.UPLOAD && questions[index].value.size > 1000)
                return {
                    status: false,
                    message: `Ai incarcat un fisier mai mare de 5MB la campul:   ${info.questionText}!!`
                };

            if (!valid)
                return {status: false, message: `Ai gresit campul:   ${info.questionText}!!`};
        }
    }

    return {status, message};
};
