export enum InputType  {
    SHORT = 'short-text',
    LONG = 'long-text',
    COMBO_BOX = 'combo-box',
    RADIO_BUTTON = 'radio-button',
    PHONE_NUMBER = 'phone-number',
    UPLOAD = 'upload',
    MAIL = 'email',
    CNP = 'cnp',
    HEADING = 'heading',
    CHECK_BOX = 'check-box',
}
