import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Landing.css';

const image = require('../../assets/images/generic-jsu.png');

export const Landing = () => {
    const navigate = useNavigate();

    const onPress = () => navigate('/form');

    const renderImage = () => (
        <img height={'70%'} className={'landingImage'} src={image} alt="oglinda"/>
    );

    const renderButton = () => (
        <button onClick={onPress} className={'landingButton'}>ÎNSCRIE-TE AICI!</button>
    );

    return (
        <div className={'landingContainer'}>
            {renderImage()}
            {renderButton()}
        </div>
    );
};