import React from 'react';
import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Landing, Form, Success, NotFound} from "./pages";

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path={'/'} element={<Landing/>}/>
              <Route path={'/form'} element={<Form/>}/>
              <Route path={'/forms/:formName'} element={<Form/>}/>
              <Route path={'/success'} element={<Success/>}/>
              <Route path={'*'} element={<NotFound/>}/>
          </Routes>
      </BrowserRouter>
  );
}

export default App;
