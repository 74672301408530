import * as React from 'react';
import './InfoInput.css';
import {InputType} from '../../types/enum';
import {ParticipantInfo} from '../../types/classes';

interface Props {
    item: ParticipantInfo ;
    onBlur: (fieldId: string, value: string) => void;
    onChange: (value: string, fieldId: string) => void;
    onChangeFiles: (value, fileId: string) => void;
}

export const InfoInput = (props: Props) => {
    const {item} = props;
    const [value, setValue] = React.useState<string>(item?.value);

    const onBlur = () => props.onBlur(item.fieldId, value);

    const onChange = (e) => {
        setValue(e.target.value);
        props.onChange(e.target.value, item.fieldId);
    };

    const onChangeCheckBox = (e) => {
        if(e.target.checked){
            setValue(item.options[0]);
            props.onChange(item.options[0], item.fieldId);
        } else {
            setValue('');
            props.onChange('', item.fieldId);
        }
    };

    const onChangeFiles = (e) => props.onChangeFiles(e.target.files[0], item.fieldId);

    const renderComboBox = () => {
        return (
            <>
                <select required={item.required} onChange={onChange} onBlur={onBlur}>
                    <option hidden disabled selected> -- selectează o opțiune -- </option>
                    {item.options.map((option: string) => {
                        return (<option selected={value===option} value={option}>{option}</option>)
                    })}
                </select>
            </>
        )
    }

    const renderRadioButtons = () => {
        return item.options.map((option: string, index: number) => {
            if(option === '...') {
                return (
                    <div style={{flexDirection: 'row'}}>
                        <input style={{boxShadow: "none"}} onClick={() => setValue('Altele...')} type={"radio"} checked={!item.options.includes(value)}/>
                        <label style={{color: "white", marginRight: 20}} htmlFor={item.fieldId+index}>Altele</label>
                        {renderShortInput()}
                    </div>
                );
            }
            return (
                <>
                    <div onChange={onChange} onBlur={onBlur} style={{flexDirection: 'row', marginBottom: 10}}>
                        <input checked={option === value} style={{boxShadow: "none"}} type={"radio"} name={item.fieldId} value={option} id={item.fieldId+index}/>
                        <label style={{color: "white"}} htmlFor={item.fieldId+index}>{option}</label>
                    </div>
                </>
            )
        });
    };

    const renderCheckBox = () => (
        <div style={{flexDirection: 'row'}}>
            <input
                style={{boxShadow: "none"}}
                required={item.required}
                type="checkbox"
                id={item.fieldId}
                value={value}
                onChange={onChangeCheckBox}
            />
            <label style={{color: "white"}} htmlFor={item.fieldId}>Da</label>
        </div>
    );

    const renderShortInput = () => {
        return  (
            <input
                value={value}
                type={item.fieldType}
                required={item.required}
                placeholder={item.placeholder}
                onBlur={onBlur}
                onChange={onChange}
            />
        )
    }

    const renderLongInput = () => {
        return (
            <textarea
                value={value}
                required={item.required}
                placeholder={item.placeholder}
                onChange={onChange}
                onBlur={onBlur}
            />
        )
    }

    const renderPhoneInput = () => {
        return  (
            <input
                type={"number"}
                value={value}
                required={item.required}
                placeholder={item.placeholder}
                onBlur={onBlur}
                onChange={onChange}
            />
        )
    }

    const renderInputCNP = () => {
        return (
            <input
                type={"number"}
                value={value}
                required={item.required}
                placeholder={item.placeholder}
                onBlur={onBlur}
                onChange={onChange}
            />
        )
    }

    const renderUploadFile = () => {
        return(
            <input
                required={item.required}
                id={item.fieldId}
                name={item.fieldId}
                type={'file'}
                accept={'application/pdf'}
                onChange={onChangeFiles}
            />
        )
    }

    const renderInputType = () => {
        switch(item.fieldType) {
            case InputType.SHORT:
                return renderShortInput();
            case InputType.LONG:
                return renderLongInput();
            case InputType.COMBO_BOX:
                return renderComboBox();
            case InputType.RADIO_BUTTON:
                return renderRadioButtons();
            case InputType.PHONE_NUMBER:
                return renderPhoneInput();
            case InputType.UPLOAD:
                return renderUploadFile();
            case InputType.CNP:
                return renderInputCNP();
            case InputType.CHECK_BOX:
                return renderCheckBox();
            default:
                return  (
                    <input
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        type={item.fieldType}
                        required={item.required}
                        placeholder={item.placeholder}
                    />
                )
        }
    }

    if(item.fieldType === InputType.HEADING)
        return <h2 style={{marginBottom: -20, color: "#0b132b"}}>{item.fieldLabel}</h2>

    return (
        <div className={'questionContainer'}>
            <label className={'questionText'}>{item.fieldLabel}{item.required ? ' *' : null}</label>
            {renderInputType()}
        </div>
    )
}