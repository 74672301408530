import * as React from 'react';
import './QuestionInput.css';
import {InputType} from '../../types/enum';
import {Question} from '../../types/classes';

interface Props {
    item: Question;
    onBlur: (questionId: string, value: string) => void;
    onChange: (value:string, questionId: string) => void;
    onChangeFiles: (value, fileId: string) => void;
}

export const QuestionInput = (props: Props) => {
    const {item} = props;
    const [value, setValue] = React.useState<string>(item?.value);

    const onBlur = () => props.onBlur(item.questionId, value);

    const onChange = (e) => {
        setValue(e.target.value);
        props.onChange(e.target.value, item.questionId);
    };

    const onChangeCheckBox = (e) => {
        if(e.target.checked){
            setValue(item.options[0]);
            props.onChange(item.options[0], item.questionId);
        } else {
            setValue('');
            props.onChange('', item.questionId);
        }
    };

    const onChangeFiles = (e) => props.onChangeFiles(e.target.files[0], item.questionId);

    const renderComboBox = () => {
        return (
            <select required={item.required} onChange={onChange} onBlur={onBlur}>
                <option hidden disabled selected> -- selectează o opțiune -- </option>
                {item.options.map((option: string) => {
                    return (<option selected={value===option} value={option}>{option}</option>)
                })}
            </select>
        )
    }

    const renderRadioButtons = () => {
        return item.options.map((option: string, index: number) => {
            if(option === '...') {
                return (
                    <div style={{flexDirection: 'row'}}>
                        <input style={{boxShadow: "none"}} onClick={() => setValue('Altele...')} type={"radio"} checked={!item.options.includes(value)}/>
                        <label style={{color: "white", marginRight: 20}} htmlFor={item.questionId+index}>Altele</label>
                        {renderShortInput()}
                    </div>
                );
            }
            return (
                <>
                    <div onChange={onChange} onBlur={onBlur} style={{flexDirection: 'row', marginBottom: 10}}>
                        <input style={{boxShadow: "none"}} type={"radio"} name={item.questionId} value={option} id={item.questionId+index}/>
                        <label style={{color: "white"}} htmlFor={item.questionId+index}>{option}</label>
                    </div>
                </>
            )
        });
    };

    const renderUploadFile = () => {
        return(
            <input
                id={item.questionId}
                name={item.questionId}
                type={'file'}
                accept={'application/pdf'}
                onChange={onChangeFiles}
            />
        )
    }

    const renderShortInput = () => {
        return  (
            <input
                value={value}
                type={item.questionType}
                required={item.required}
                placeholder={item.placeholder}
                onBlur={onBlur}
                onChange={onChange}
            />
        )
    }

    const renderLongInput = () => {
        return (
            <textarea
                value={value}
                required={item.required}
                placeholder={item.placeholder}
                onChange={onChange}
                onBlur={onBlur}
            />
        )
    }

    const renderCheckBox = () => (
        <div style={{flexDirection: 'row'}}>
            <input
                style={{boxShadow: "none"}}
                required={item.required}
                type="checkbox"
                id={item.questionId}
                value={value}
                onChange={onChangeCheckBox}
            />
            <label style={{color: "white"}} htmlFor={item.questionId}>Da</label>
        </div>
    );

    const renderInputType = () => {
        switch(item.questionType) {
            case InputType.SHORT:
                return renderShortInput();
            case InputType.LONG:
                return renderLongInput();
            case InputType.COMBO_BOX:
                return renderComboBox();
            case InputType.RADIO_BUTTON:
                return renderRadioButtons();
            case InputType.UPLOAD:
                return renderUploadFile();
            case InputType.CHECK_BOX:
                return renderCheckBox();
            default:
                return  (
                    <input
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        type={item.questionType}
                        required={item.required}
                        placeholder={item.placeholder}
                    />
                )
        }
    }

    if(item.questionType === InputType.HEADING)
        return <h2 style={{marginBottom: -20, color: "#0b132b"}}>{item.questionText}</h2>

    return (
        <div className={'questionContainer'}>
            <label className={'questionText'}>{item.questionText}{item.required ? ' *' : null}</label>
            {renderInputType()}
        </div>
    )
}
