import React from 'react';

import './NotFound.css';

export const NotFound = () => {
    return (
      <div className={"notFoundContainer"}>
          <h1>OOPS!</h1>
          <h2>ACEASTĂ PAGINĂ ESTE DE NEGĂSIT!</h2>
      </div>
    );
}