import React, {useEffect, useState} from 'react';
import {FormData} from '../../types/classes'
import {InfoInput, QuestionInput, SubmitButton} from "../../components";

import './ApplicationForm.css';

const seeYouSoon = require('src/assets/images/see-you-soon.png');

interface Props {
    inputFields: FormData;
    onSubmitForm: (participantInfo, questions, files) => void;
    onBlurSaveParticipantInfo: (fieldId: string, value: string) => void;
    onBlurSaveQuestion: (fieldId: string, value: string) => void;
    buttonLoading: boolean;
    checkIfFormIsOpen: boolean;
}

export const ApplicationForm = (props: Props) => {
    const [participantInfo, setParticipantInfo] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        const initialParticipantInfo = [];
        const initialQuestions = [];

        props.inputFields?.participantInfo?.forEach(info => {
           if(info.value)
               initialParticipantInfo.push({value: info.value, fieldId: info.fieldId})
        });
        props.inputFields?.questions?.forEach(info => {
            if(info.value)
                initialQuestions.push({value: info.value, questionId: info.questionId})
        });

        setParticipantInfo(initialParticipantInfo);
        setQuestions(initialQuestions);
    },[props.inputFields]);

    const onChangeParticipantInfo = (value: string, fieldId: string) => {
        const newParticipantInfo = participantInfo;
        const index = newParticipantInfo.findIndex(item => item.fieldId === fieldId);

        if (index !== -1) newParticipantInfo[index] = {value, fieldId};
        else newParticipantInfo.push({value,fieldId});
        setParticipantInfo(newParticipantInfo);
    }

    const onChangeQuestion = (value: string, questionId: string) => {
        const newQuestions = questions;
        const index = newQuestions.findIndex(item => item.questionId === questionId)

        if (index !== -1) newQuestions[index] = {value, questionId};
        else newQuestions.push({value,questionId});

        setQuestions(newQuestions);
    }

    const onChangeFiles = (value, fileId) => {
        const newFiles = files;

        const index = newFiles.findIndex(file => file.fileId === fileId)

        if (index !== -1) newFiles[index] = {value, fileId};
        else newFiles.push({value, fileId});

        setFiles(newFiles);
    }

    const onSubmitForm = () => props.onSubmitForm(participantInfo, questions, files);

    const renderParticipantInfo = () =>  {
        return props.inputFields?.participantInfo?.map((item, index) => {
            return (
                <InfoInput
                    key={item.fieldId+index}
                    item={item}
                    onBlur={props.onBlurSaveParticipantInfo}
                    onChange={onChangeParticipantInfo}
                    onChangeFiles={onChangeFiles}
                />
            )}
        )
    }

    const renderQuestions = () => {
       return props.inputFields?.questions?.map((item, index) => {
            return (
                <QuestionInput
                    key={item.questionId+index}
                    item={item}
                    onBlur={props.onBlurSaveQuestion}
                    onChange={onChangeQuestion}
                    onChangeFiles={onChangeFiles}
                />
            )}
        )
    }

    let now = new Date();
    let start = new Date(props.inputFields?.startRegistration);
    let end = new Date(props.inputFields?.endRegistration);
    if (props.checkIfFormIsOpen && (start > now || now > end)) {
        return <img id="seeYouSoon" src={seeYouSoon}/>
    }

    return(
        <>
            <form className={"formContainer"}>
                {renderParticipantInfo()}
                {renderQuestions()}
            </form>
            <SubmitButton buttonLoading={props.buttonLoading} onPress={onSubmitForm}/>
        </>
    )
}