import React from 'react';

import './Success.css';

export const Success = () => {
    return (
        <div className={"successContainer"}>
            <text className={"successOrange"}>FELICITĂRI!<br/>APLICAȚIA TA A FOST TRIMISĂ CU SUCCES!<br/></text>
            <text className={"successBlue"}>EȘTI CU UN PAS MAI APROAPE DE<br/>O VARĂ DE NEUITAT!</text>
        </div>
    );
}